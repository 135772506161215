<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <h2>我的游戏</h2>
      <div class="my-game">
        <div class="between">
          <div class="sub-nav">
            <li
              v-for="(item, index) in subTab"
              :key="item"
              :class="[index == 0 ? 'sub-act' : '']"
              @click="changeSubTab(index)"
            >
              {{ item }}
            </li>
          </div>
          <p class="sub-tip">
            <span>已创建</span>
            <span class="sub-tip-total"> {{ total }} </span>个
          </p>
        </div>
        <div class="line"></div>
        <ListLoad v-if="listLoad && !loading" />
        <div class="game-list-wrap">
          <div class="game-left">
            <el-menu
              unique-opened
              @select="handleSelect"
              :default-active="activeIndex"
              :default-openeds="openIndex"
            >
              <div v-for="(item, index) in submenuList" :key="index">
                <el-menu-item :index="String(item.cate_id)" v-if="!item.son">{{
                  item.name
                }}</el-menu-item>
                <el-submenu :index="String(item.cate_id)" v-else>
                  <template slot="title">{{ item.name }}</template>
                  <div v-for="(list, i) in item.son" :key="i">
                    <el-menu-item :index="String(list.cate_id)">{{
                      list.name
                    }}</el-menu-item>
                  </div>
                </el-submenu>
              </div>
            </el-menu>
          </div>
          <div class="game-right">
            <ul class="game-list" v-if="!listLoad">
              <li
                class="game-item"
                v-for="(item, index) in list"
                :key="item.config_id"
              >
                <img class="cover" :src="preSrc + item.cover" alt="" />
                <h2 :title="item.title" class="ellipsis">{{ item.title }}</h2>
                <div class="flex favor-work">
                  <p
                    v-if="item.config_id"
                    class="preview flex"
                    @click.stop="
                      previewCreateGame(
                        item.game_id,
                        item.config_id,
                        item.title
                      )
                    "
                  >
                    <i class="iconfont icon-yulan"></i>
                    <span>预览</span>
                  </p>
                  <p
                    v-else
                    class="preview flex"
                    @click.stop="
                      previewCreateGame(item.game_id, '', item.title)
                    "
                  >
                    <i class="iconfont icon-yulan"></i>
                    <span>预览</span>
                  </p>
                  <span class="slash">/</span>
                  <WorkbenchCancel
                    v-if="item.issue_status == 1"
                    :cType="0"
                    :cId="item.config_id"
                    :cStatus="2"
                    :cItem="item"
                    :cIndex="index"
                    @workbenchCancel="workbenchCancel"
                  />
                  <Workbench
                    v-if="item.issue_status == 2"
                    :cType="0"
                    :cId="item.config_id"
                    :cTitle="item.title"
                    :cStatus="1"
                    :cItem="item"
                    :cIndex="index"
                    @handleWorkbench="handleWorkbench"
                  />
                  <span class="slash">/</span>
                  <span
                    class="delete"
                    @click.stop="handleGameDel(item.config_id)"
                  >
                    <i class="iconfont icon-shanchu"></i>
                    删除
                  </span>
                  <span class="slash">/</span>
                  <span class="delete" @click.stop="moveGame(item.config_id)">
                    <i class="iconfont icon-yidong"></i>
                    移动
                  </span>
                </div>
              </li>
            </ul>
            <div class="pagination-con" v-if="list.length > 0 && total > 5">
              <el-pagination
                :page-size="pageSize"
                :pager-count="5"
                layout="prev, pager, next"
                :current-page="page"
                :total="total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
            <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
          </div>
        </div>
      </div>
      <!-- 分页 -->

      <!-- 分页end -->
    </div>
    <!-- 移动弹窗 -->
    <el-dialog
      title="移动目录"
      :visible.sync="popShow"
      width="500px"
      custom-class="cate-pop"
      center
      @close="closePop"
    >
      <div class="pop-content">
        <div class="game-info-wrap">
          <div class="game-pic">
            <img :src="preSrc + moveGameInfo.game_cover" alt="" />
          </div>
          <div class="game-info">
            <h3 class="game-title">{{ moveGameInfo.title }}</h3>
            <p class="game-desc ellip-2">{{ moveGameInfo.desc }}</p>
          </div>
        </div>
        <div class="game-cate-wrap">
          <label class="cate-label">所属目录:</label>
          <el-cascader
            v-model="cateId"
            :options="treeCate"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'cate_id',
              children: 'son',
            }"
            @change="handleChange"
          ></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePop">取 消</el-button>
        <el-button type="primary" @click="changeGameCate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreviewCreate } from "@/tools/GlobalFunction";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false,
      loading: true,
      submenuList: [],
      subTab: ["已创建", "已购买"],
      list: [],
      page: 1,
      total: 0,
      pageSize: 6,
      cateList: [],
      activeIndex: "",
      openIndex: [],
      popShow: false,
      cateId: [],
      treeCate: [],
      moveGameInfo: {},
    };
  },
  created() {
    let _self = this;
    _self.gameList(_self.page);
    _self.getCateTree();
  },
  methods: {
    //游戏分类（树形）
    getCateTree() {
      let _self = this;
      _self.axios.post(config.url.treeCate).then((res) => {
        if (res.code == 200) {
          _self.treeCate = res.data && res.data.length > 0 ? res.data : [];
          _self.submenuList = res.data && res.data.length > 0 ? res.data : [];
          _self.submenuList.unshift({
            name: "全部",
            cate_id: 0,
          });
          _self.cateId = [0];
          _self.activeIndex = "0";
          _self.openIndex = [0];
          _self.loading = false;
          _self.listLoad = false;
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
          _self.loading = false;
          _self.listLoad = false;
        }
      });
    },
    //我的游戏 type 1创建 2购买
    gameList(page, cate_id = 0) {
      let _self = this;
      _self.axios
        .post(
          config.url.myGame,
          _self.$qs.stringify({ page, size: _self.pageSize, type: 1, cate_id })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data.list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    handleSelect(key, keyPath) {
      if (key == this.cateId[this.cateId.length - 1] && key != 0) {
        return;
      }
      this.cateId = keyPath;
      this.activeIndex = keyPath[keyPath.length - 1];
      this.openIndex = [...keyPath];
      this.page = 1;
      if (keyPath.length > 1) {
        this.gameList(1, keyPath[1]);
      } else {
        this.gameList(1);
      }
    },
    // 创建游戏
    createGame(id) {
      let _self = this;
      _self.$router.push({
        name: "game-create",
        params: {
          id: id,
        },
      });
    },
    // 预览
    previewCreateGame(gameId, configId = "", title) {
      let _self = this;
      handlePreviewCreate(gameId, configId, title, _self);
    },
    // 切换子选项卡
    changeSubTab(index) {
      let _self = this;
      if (index == 0) {
        return;
      }
      _self.$router.push({
        name: "buy-game",
      });
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 删除游戏
    handleGameDel(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameDelete, _self.$qs.stringify({ config_id: id }))
        .then((res) => {
          if (res.code == 200) {
            res.tip == 1
              ? _self.showTip("success", res.msg)
              : _self.showTip("success", "删除成功");
            _self.page = 1;
            _self.gameList(_self.page, _self.cateId[_self.cateId.length - 1]);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 移动目录
    moveGame(config_id) {
      let _self = this;
      _self.axios
        .post(
          config.url.gamePics,
          _self.$qs.stringify({ config_id: config_id })
        )
        .then((res) => {
          if (res.code == 200) {
            console.log(res, _self.treeCate);
            _self.moveGameInfo = res.data;
            let treeCate = _self.treeCate;
            let cate_id = res.data.cate_id;
            let oneArr = treeCate.filter((item) => item.cate_id == cate_id);
            if (oneArr && oneArr.length > 0) {
              _self.cateId = [oneArr[0].cate_id];
            } else {
              let newArr = [];
              treeCate.forEach((item) => {
                if (item.son) {
                  newArr.push(...item.son);
                }
              });
              let secondArr = newArr.filter((item) => item.cate_id == cate_id);
              if (secondArr && secondArr.length > 0) {
                _self.cateId = [secondArr[0].superior_id, secondArr[0].cate_id];
              }
            }
            _self.popShow = true;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    handleChange(e) {
      this.cateId = e;
    },
    // 关闭弹窗
    closePop() {
      let _self = this;
      _self.moveGameInfo = {};
      _self.popShow = false;
    },
    changeGameCate() {
      let _self = this;
      let config_id = _self.moveGameInfo.config_id;
      let cate_id = _self.cateId[_self.cateId.length - 1];
      _self.loading = true;
      _self.axios
        .post(
          config.url.moveConfigCate,
          _self.$qs.stringify({ config_id: config_id, cate_id: cate_id })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.showTip("success", res.msg);
            _self.moveGameInfo = {};
            _self.popShow = false;
            _self.page = 1;
            _self.gameList(1, _self.cateId[_self.cateId.length - 1]);
            this.activeIndex = String(_self.cateId[_self.cateId.length - 1]);
            this.openIndex = _self.cateId;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.gameList(val, _self.cateId[_self.cateId.length - 1]);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.game-list-wrap {
  display: flex;
  position: relative;
  padding-left: 240px;
  background: #fff;
  min-height: 330px;
}
.game-left {
  width: 210px;
  position: absolute;
  left: 0;
  top: 30px;
  height: calc(100% - 110px);
  overflow-y: auto;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-right: 30px;
  ::v-deep .el-submenu__title:hover {
    background-color: #fff7f1 !important;
  }
  ::v-deep .el-submenu .el-submenu__title {
    font-size: 16px;
    color: #1d1d1d;
  }
  ::v-deep .el-submenu.is-opened .el-submenu__title {
    background: #fff7f1 !important;
  }
  .el-menu-item:hover {
    background: #fff7f1 !important;
  }
  .el-menu-item:focus {
    background: transparent;
  }
  .el-menu-item {
    font-size: 14px;
    color: #5a5a5a;
  }
  .el-menu-item.is-active {
    color: $color-main;
    // background: #fff7f1 !important;
  }
}
.game-right {
  width: 100%;
}
.list {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background: #fff;
  border-radius: $border-radius-main;
  h2 {
    margin-bottom: 40px;
  }
  .list-item {
    background: #f9f9f9;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: $border-radius-main;
    margin-bottom: 30px;
    > p {
      margin-bottom: 20px;
      color: #9f9f9f;
      font-size: 16px;
    }
  }
}
.favor i,
.work img,
.btn:hover,
.sub-nav:hover,
.favor-work:hover {
  cursor: pointer;
}
.favor-work {
  color: $font-color-9f;
  justify-content: flex-start;
  .icon-yulan {
    color: #34c3f9;
    margin: 3px 6px 0 0;
  }
  .slash {
    margin: 0 10px;
  }
  .favor {
    margin-top: -3px;
  }
  .favor i {
    margin-right: 6px;
  }
  .work {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      width: 18px;
      height: 18px;
      object-fit: contain;
      margin-right: 10px;
    }
  }

  .icon-bianji1 {
    color: #03cca6;
  }
}

.my-game,
.my-favor,
.my-workplace {
  width: 920px;
  .sub-nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    li {
      font-size: 18px;
      color: $font-color-51;
      width: 120px;
      text-align: center;
      flex-shrink: 0;
      flex-grow: 0;
    }
    li::after {
      content: "";
      display: block;
      margin: 20px auto 0;
      width: 50px;
      height: 5px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    li.sub-act::after {
      content: "";
      display: block;
      margin: 20px auto 0;
      width: 50px;
      height: 5px;
      background: $color-main;
      border-radius: 5px;
    }
  }
}
.line {
  width: 100%;
  height: 10px;
  background: #f9f9f9;
  margin-top: -8px;
  border-radius: 5px;
}
.game-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  background: #fff;
  width: 100%;
  .game-item {
    width: 320px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: $border-radius-main;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    margin: 0 40px 20px 0;
    .cover {
      width: 160px;
      height: 160px;
      object-fit: contain;
      margin: 0 auto 16px;
    }
    h2 {
      font-size: 18px;
      color: $font-color-main;
      margin: 0 auto 24px;
      text-align: center;
    }
  }
  .game-item:nth-child(2n) {
    margin: 0 0 20px 0;
  }
}
.my-favor-con {
  margin-top: 30px;
}
.game-list-favor {
  .game-item {
    width: 280px;
    height: 250px;
  }
}
.sub-tip {
  font-size: 18px;
  color: $font-color-51;
  .sub-tip-total {
    color: $color-main;
  }
}
.list-item.workpalce-course-list {
  padding: 0;
  background: #fff;
  margin-top: 30px;
  .item-r {
    h3 {
      margin-bottom: 16px;
    }
  }
}
.delete {
  color: $font-color-9f;
  .icon-shanchu {
    color: #ff4d4f;
  }
  .icon-yidong {
    color: #7789ff;
    font-size: 18px;
  }
}
.buy {
  width: 60px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #34c3f9;
  background: #eefaff;
  border: 1px solid #34c3f9;
  border-radius: 5px;
}
.hide {
  opacity: 0;
}
.game-left::-webkit-scrollbar {
  width: 3px;
}
/*滚动条里面小方块*/
.game-left::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: $color-main;
}
/*滚动条里面轨道*/
.game-left::-webkit-scrollbar-track {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
.orange-btn {
  background: $color-main;
}
::v-deep .cate-pop {
  border-radius: 20px !important;
}
.cate-pop {
  .game-info-wrap {
    display: flex;
    width: 400px;
    margin: 0 auto 20px;
    margin-bottom: 30px;
    .game-pic {
      width: 120px;
      margin-right: 20px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    .game-info {
      width: 100%;
      .game-title {
        font-size: 20px;
        color: #1d1d1d;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .game-cate-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 0 auto 20px;
    .cate-label {
      font-size: 16px;
      color: #636363;
      margin-right: 20px;
      flex-shrink: 0;
      width: 120px;
    }
    .el-cascader {
      font-size: 16px;
      line-height: 1;
      width: 100%;
    }
  }
  .el-button {
    border-radius: 100px;
    padding: 10px 30px;
    border-color: $color-main;
  }
}
</style>
